import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled";
import React from "react";
import { ToggleSwitchWrapper } from "shared/shared_styled_comps/components.styled";
import { ButtonContainer, OutlinedMarketingButton } from "shared/shared_styled_comps/components.styled";

interface ToggleSwitchProps {
  checked: boolean;
  onChange: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
}) => {
  return (
    <ToggleSwitchWrapper onClick={onChange}>
      <div className={`switch ${checked ? "on" : "off"}`} />
    </ToggleSwitchWrapper>
  );
};

interface TwoButtonProps {
  leftLink?: string;
  rightLink?: string;
  leftText?: string;
  rightText?: string;
  leftAlign?: boolean;
}

export const TwoButtonDiv: React.FC<TwoButtonProps> = ({
  leftLink = "",
  rightLink = "",
  leftText,
  rightText,
  leftAlign,
}) => {
  return (
    <ButtonContainer style={{ justifyContent: leftAlign ? "flex-start" : "center" }}>
      {leftText && <MarketingGreenButtonWrapper
        onClick={() => {
          window.location.assign(leftLink);
        }}
        width={250}
      >
        {leftText}
        </MarketingGreenButtonWrapper>
      }
      {rightText && (
        <MarketingGreenButtonWrapper
          onClick={() => {
            window.location.assign(rightLink);
          }}
        width={250}
      >
          {rightText}
        </MarketingGreenButtonWrapper>
      )}
    </ButtonContainer>
  );
};
