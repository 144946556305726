import styled, {css} from "styled-components";
import { colors } from "colors";

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 25px 10px 25px;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 10000;
  transition: transform ease 0.3s;
  .long-link {
    min-width: 180px;
    @media (max-width: 1375px) {
      min-width: 100px;
    }
  }
  .flex-span {
    @media(max-width: 990px) {
      display: inline-flex;
    }
    align-items: center; /* Vertically centers the content */
    justify-content: center; /* Horizontally centers the content (optional) */
  }
  &:hover {
    transform: scale(1.01);
  }
  .blocked {
    opacity: 0.5;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 20;
  }
  .nav-inner-wrapper {
    display: flex;
    align-items: center;
    width: inherit;
    background-color: ${colors.darkGray};
    border-radius: 50px;
    height: 70px;
    padding: 0 35px 0 35px;
    box-shadow: 0px 3.91px 3.91px 0px #00000040 inset;
    .logo-wrapper {
      flex: 0 1 28%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      svg {
        width: 142px;
        path {
          fill: ${colors.mainGreen};
        }
      }
    }
    .nav-wrapper {
      display: none;
      &.open {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: #2f2f2f;
      }
      hr {
        display: none;
      }
      a {
        text-decoration: none;
        color: ${colors.white};
        transition: color ease 0.1s;
        width: fit-content;
        margin-top: 15px;
        margin-bottom: 15px;
        &:hover {
          color: ${colors.green};
        }
        h3 {
          font-size: 40px;
        }
      }

      .free-trial {
        margin: 0;
        margin-top: 15px;
      }
      h3 {
        font-weight: 600 !important;
        font-size: 20px;
      }
      /* box-shadow: 0px 3.914240598678589px 3.914240598678589px 0px #00000040
        inset; */
    }
  }

  .mobile-nav-button {
    display: flex;
    justify-content: flex-end;
    height: fit-content;
    width: fit-content;
    flex: 0 1 66%;
  }
  @media (min-width: 906px) {
    padding: 30px 45px 30px 45px;
  }
  @media (min-width: 1270px) {
    .nav-inner-wrapper {
      .nav-wrapper {
        opacity: 1;
        display: flex;
        flex: 0 1 66%;
        justify-content: flex-end;
        align-items: center;
        hr {
          display: block;
          width: 50px;
          transform: rotate(90deg);
          border: none;
          height: 1px;
          background-color: ${colors.grayWhite};
          margin-left: 0;
          margin-right: 0;
        }
        a {
          margin-right: 25px;
          width: unset;
          h3 {
            font-size: 20px;
          }
        }
        a.login {
          margin-left: 25px;
        }
        .free-trial {
          margin: 0;
        }
      }
      .mobile-nav-button {
        display: none;
      }
    }
  }
`;

const getProgressRotation = (percent: number) => {
  // Ensure percent is between 0 and 100
  const normalizedPercent = Math.min(100, Math.max(0, percent));
  
  // For percentages less than 50, we only rotate the first half
  if (normalizedPercent <= 50) {
    return css`
      clip: rect(0, 57px, 57px, 28.5px);
      .circle-progress-right {
        display: none;
      }
      .circle-progress-left {
        transform: rotate(${(normalizedPercent / 100) * 360}deg);
      }
    `;
  }
  
  // For percentages more than 50, we show both halves
  return css`
    clip: rect(auto, auto, auto, auto);
    .circle-progress-right {
      transform: rotate(180deg);
    }
    .circle-progress-left {
      transform: rotate(${(normalizedPercent / 100) * 360}deg);
    }
  `;
};

export const CircularProgress = styled.div<{ percent: number }>`
  width: 57px;
  height: 57px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  /* Center text */
  span {
    color: ${colors.mainGreen};
    font-size: 14px;
    font-weight: 700;
    z-index: 2;
  }

  /* Background circle */
  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border: 1px solid ${colors.middleGray};
    border-radius: 50%;
  }

  /* Progress circle wrapper */
  .circle-progress {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    ${props => getProgressRotation(props.percent)}
  }

  /* Progress circle halves */
  .circle-progress-left,
  .circle-progress-right {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 3px solid ${colors.mainGreen};
    border-radius: 50%;
    clip: rect(0, 28.5px, 57px, 0);
    transition: transform 0.3s ease;
  }
`;


export const AppNavigationWrapper = styled.div`
  width: 100%;
  height: 113px;
  margin: 0 auto;
  max-width: 1928px;
  margin-left: 0;
  margin-right: 0;
  background-color: ${colors.deepGray} !important;
  border-bottom: 1px solid ${colors.middleGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  background-color: #5b5f64;
  padding-left: 160px;
  .right-wrapper {
    display: flex;
    align-items: center;
  }
  .title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    svg {
      height: 30px;
      width: 30px;
      margin-right: 12px;
      filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
      path {
        fill: ${colors.mainGreen};
      }
    }
    h2 {
      color: ${colors.mainGreen};
    }
  }
`;

export const User = styled.div`
  border-radius: 300px;
  width: 230px;
  height: 57px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  background-color: #35373e;
  box-shadow: 0px 6px 13px 0px #0000001a;
  transition: transform 0.3s ease-in-out;
  position: relative;
  z-index: 1001;
  &:hover {
    transform: scale(1.02);
  }
  /* box-shadow: 0px 23px 23px 0px #00000017;
  box-shadow: 0px 51px 31px 0px #0000000d;
  box-shadow: 0px 91px 36px 0px #00000003;
  box-shadow: 0px 143px 40px 0px #00000000; */

  .user-wrapper {
    width: 75%;
    height: 48px;
    display: flex;
    border-radius: 100px;
    margin-right: 4px;
    justify-content: space-between;
    align-items: center;
    background-color: #5b5f64;
    cursor: pointer;
    svg {
      margin-right: 7px;
    }
  }
  .pfp {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    border-radius: 100%;
    margin-left: 2px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
  .sub-text {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
  }
  .text {
    width: 65%;
    margin-left: 10px;
  }
  p {
    margin: 0;
  }
  .letter-wrapper {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    border-radius: 100%;
    background-color: ${colors.mainGreen};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
  }
`;

export const DropDownMenu = styled.div`
  position: absolute;
  background-color: ${colors.lightGray};
  z-index: 1000;
  width: 220px;
  height: 440px;
  top: 58px;
  right: 55px;
  padding: 24px 16px 24px 16px;
  padding-top: 40px;
  border-radius: 10px;
  gap: 21px;
  box-shadow: 0px 8px 18px 0px #0000001a, 0px 32px 32px 0px #00000017,
    0px 73px 44px 0px #0000000d, 0px 129px 52px 0px #00000003,
    0px 202px 57px 0px #00000000;
  color: ${colors.black};
  a {
    text-decoration: none;
  }
  p {
    text-align: left;
    font-size: 14px;
    line-height: 27px;
  }
  p.link {
    font-weight: 400;
    margin: 3px 0;
    padding-left: 10px;
    color: ${colors.black};
    &:hover {
      color: ${colors.textWhite};
      background-color: ${colors.black};
    }
  }
  h4 {
    color: ${colors.black};
  }
  .email {
    font-weight: 700;
    margin-top: 0px;
    font-size: 11px;
  }
  hr {
    width: 100%;
    background-color: ${colors.lightGray};
    height: 1px;
    border: none;
    margin: 20px 0;
  }
  .first {
    margin-top: 5px;
  }
  .tour-link {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    font-size: 14px;
    line-height: 27px;
    background: none;
    color: ${colors.black};
    cursor: pointer;
    &:hover {
      background-color: ${colors.darkGray};
      color: ${colors.textWhite};
    }
  }
  button {
    color: ${colors.textWhite};
    background-color: ${colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 33px;
    padding: 14.99px 44.98px 14.99px 44.98px;
    border-radius: 3.95px;
    gap: 6.31px;
    border: 1px solid ${colors.lightGray};
    font-weight: 700px;
    font-size: 12px;
    margin-bottom: 10px;

    &:hover {
      background-color: ${colors.textWhite};
      color: ${colors.darkGray};
    }
  }
`;

interface ArrowWrapper {
  showdropdown: boolean;
}

export const ArrowWrapper = styled.div<ArrowWrapper>`
  ${(props) =>
    props.showdropdown &&
    `
  `}
`;

export const Dropdown = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? "block" : "none")};
  position: absolute;
  @media (max-width: 990px) {
    position: relative;
    left: 0;
    width: auto;
    height: auto;
    padding: 0;
    margin-top: 10px;
  }
  top: 0px;
  left: -164px;
  background-color: ${colors.black};
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  z-index: 10000;
  height: 200px;
  width: 475px;
  border-radius: 5px;
  margin-top: 20px;
  .flex-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    @media (max-width: 990px) {
      flex-wrap: wrap;
      width: 140px;
      .link-wrapper {
        width: auto;
        height: auto;
        h3 {
          font-size: 24px !important;
        }
      }
    }
  }
  .link-wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    a {
      margin: 0 !important;
      width: 100% !important;
    }
  }
`;
