import React from "react";
import {
  Section,
  VerticalSection,
  MarketingGreenButtonWrapper,
} from "../MarketingHomepage.styled";
import HeroBottomLogos from "static/svgs/HeroBottomLogos";
import Dashboard from "static/images/dashboard.png";
import { HeroSectionWrapper } from "./HeroSection.styled";
import { useNavigate } from "react-router-dom";
import Sparkle from "static/svgs/Sparkle";
import { StructuredText } from "react-datocms";
import vantaLogo from "assets/logos/vanta_soc2_logo.svg";
import gdprLogo from "assets/logos/gdpr_logo.png";
import ccpaLogo from "assets/logos/ccpa_logo.png";
import { TwoButtonDiv } from "shared/shared_components/components";

interface DatoImage {
  url: string;
}

interface HomePage {
  headerText?: any;
  headerSubtext?: string;
  headerButtonText?: string;
  heroImage?: DatoImage;
}

type Props = {
  data: HomePage;
};

const HeroSection = (props: Props) => {
  const navigate = useNavigate();
  return (
    <HeroSectionWrapper>
      <div className="section-left">
        <div>
          {props.data.headerText && (
            <StructuredText data={props.data.headerText.value} />
          )}
          <h4>{props.data && props.data.headerSubtext}</h4>
          <TwoButtonDiv
            rightLink="/contact-us"
            rightText="Contact Sales"
            leftLink="/signup"
            leftText=""
            leftAlign={true}
          />
          <div className="flex-wrapper">
            <div className="vanta-logo">
              <img src={vantaLogo} alt="Vanta SOC 2 Logo" />
            </div>
            <div className="gdpr-logo">
              <img src={gdprLogo} alt="Vanta SOC 2 Logo" />
            </div>
            <div className="ccpa-logo">
              <img src={ccpaLogo} alt="Vanta SOC 2 Logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-right">
        <div style={{ position: "relative", width: "inherit" }}>
          {/* <div className="star-box">
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <div className="svg-wrapper">
                <Sparkle />
              </div>
              Generate a summary of this deal for my manager.
            </div>
          </div>
          <div className="star-box box2">
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              <div className="svg-wrapper">
                <Sparkle />
              </div>
              What are some questions I can ask to better understand the
              prospect’s decision process?
            </div>
          </div> */}
          <img src={props.data.heroImage?.url} />
        </div>
      </div>
    </HeroSectionWrapper>
  );
};

export default HeroSection;
