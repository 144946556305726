import React, { FC, useEffect, useState, useRef } from "react"
import { MarketingHomepageWrapper } from "./MarketingHomepage.styled"
import Ratings from "static/svgs/Ratings"
import HeroSection from "./HeroSection/HeroSection"
import TailoredSection from "./TailoredSection/TailoredSection"
import SaveTimeSection from "./SaveTimeSection/SaveTimeSection"
import HowItWorksSection from "./HowItWorksSection/HowItWorksSection"
import IntegrationSection from "./IntegrationSection/IntegrationSection"
import DealAssessmentSection from "./DealAssessmentSection/DealAssessmentSection"
import PersonalizedSection from "./PersonalizedSection/PersonalizedSection"
import PricingSection from "./PricingSection/PricingSection"
import LogoScroller from "./LogoScroller/LogoScroller"
import FAQSection from "./FAQSection/FAQSection"
import StepUpSection from "./StepUpSection/StepUpSection"
import { getHomepageData } from "cms/helpers"
import parkadeLogo from "assets/logos/Parkade_Logo.png"
import oracleLogo from "assets/logos/Oracle_logo.png"
import controlHubLogo from "assets/logos/control_hub_logo.svg"
import chronusLogo from "assets/logos/chronus_logo.png"
import userTestingLogo from "assets/logos/user_testing_logo.svg"

interface MarketingHomepageProps {}

const logos = [
  parkadeLogo,
  oracleLogo,
  controlHubLogo,
  chronusLogo,
  userTestingLogo,
];

const MarketingHomepage: FC<MarketingHomepageProps> = () => {
  const featuresRef = useRef<HTMLDivElement | null>(null)
  const [data, setData] = useState({})

  const scrollToFeatures = () => {
    const features = featuresRef.current
    if (features) {
      window.scrollTo({ top: features.offsetTop - 100, behavior: "smooth" })
    }
  }

  useEffect(() => {
    const getData = async () => {
      let response = await getHomepageData()
      setData(response.homePage)
    }
    getData()
  }, [])

  return (
    <MarketingHomepageWrapper>
      <div className="inner-wrapper">
        {/* <Ratings /> */}
        <HeroSection data={data} />
        <LogoScroller logos={logos} />
        {/* <TailoredSection data={data} scrollToFeatures={scrollToFeatures} /> */}
        <SaveTimeSection data={data} ref={featuresRef} />
        {/* <HowItWorksSection /> */}
        <IntegrationSection />
        {/* <DealAssessmentSection /> */}
        {/* <PersonalizedSection data={data} /> */}
        {/* <PricingSection /> */}
        <FAQSection />
        <StepUpSection />
      </div>
    </MarketingHomepageWrapper>
  )
}

export default MarketingHomepage
